<script lang="ts" setup>
import SkrButton from '@/components/button/SkrButton.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import InputTan from '@/components/inputs/TanInput.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'

const props = defineProps<{
  continuationData: {
    challengeId: string
  }
}>()

const { t } = useI18n()

const widgetStore = useWidgetStore()

const { tanRepository } = useApi()

const challengeId = computed(() => props.continuationData.challengeId)

const tan = ref('')

const isTanValid = computed(() => /^\d+$/.test(tan.value) && tan.value.length === 6)

const loadingStates = reactive({
  isCancelling: false,
  isConfirming: false,
})

const requestNewTan = async () => {
  await tanRepository.resend(challengeId.value)
  widgetStore.resetSnackbars()
  widgetStore.createSnackbar({
    message: t('overlay.part11_verification.snackbar.new_code_sent_message'),
  })
}

const confirm = async () => {
  if (!isTanValid.value) return

  loadingStates.isConfirming = true

  const response = await tanRepository.verify(challengeId.value, tan.value)
  const status = response.status.toLowerCase()

  switch (status) {
    case 'unverified':
      widgetStore.createSnackbar({
        message: t('overlay.mobile_aes.snackbar.wrong_code_message'),
      })
      break
    case 'expired':
      tan.value = ''
      widgetStore.createSnackbar({
        message: t('overlay.mobile_aes.snackbar.resend_code_message'),
        duration: 30000,
        action: {
          text: t('overlay.mobile_aes.snackbar.resend_code_cta_text'),
          handler: requestNewTan,
        },
      })
      break
    case 'verified':
      // Continue showing the signing overlay until we receive the final response (this could take a while)
      widgetStore.setOverlay('OverlaySigning', {})
      break
    default:
      widgetStore.createSnackbar({
        message: t('global.general_error'),
      })
      break
  }

  loadingStates.isConfirming = false
}

const cancel = async () => {
  loadingStates.isCancelling = true
  await tanRepository.cancelVerification(challengeId.value)
  loadingStates.isCancelling = false
}
</script>

<template>
  <overlay-base :transition="false">
    <div :class="['d-flex', 'flex-column', { 'fill-height justify-center': $vuetify.display.smAndUp }]">
      <v-container class="fill-height">
        <div class="content">
          <h1>{{ t('overlay.part11_verification.title') }}</h1>
          <p class="mb-8">{{ t('overlay.part11_verification.text') }}</p>
          <div>
            <div class="mb-6">{{ t('overlay.part11_verification.code.prompt') }}</div>
            <input-tan v-model="tan" @enter="confirm" />
            <i18n-t keypath="overlay.part11_verification.code.resend.text">
              <template #link>
                <a href="#" @click="requestNewTan">{{ $t('overlay.part11_verification.code.resend.link_text') }}</a>
              </template>
            </i18n-t>
          </div>
          <skr-button-group class="actions" size="lg" stacked>
            <skr-button
              data-cy="confirm_button"
              :disabled="loadingStates.isConfirming || !isTanValid"
              :loading="loadingStates.isConfirming"
              @click="confirm"
            >
              {{ t('global.confirm') }}
            </skr-button>
            <skr-button
              type="text"
              :disabled="loadingStates.isCancelling"
              :loading="loadingStates.isCancelling"
              @click="cancel"
            >
              {{ t('global.cancel') }}
            </skr-button>
          </skr-button-group>
        </div>
      </v-container>
    </div>
  </overlay-base>
</template>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  max-width: $site-width-narrow
  margin: 0 auto

  .actions
    margin-top: 8 * $bw
    max-width: 50%

  > h1
    line-height: 1.2
    text-align: center
    margin-bottom: 10 * $bw
    +h1
</style>
